import { imageAdapter } from '@/adapters/constructor/utils/image';

const slideAdapter = ({ data }) => {
  return {
    text: data.text,
    title: data.title,
    banner: imageAdapter(data.banner),
  };
};

export default ({ data, items }) => {
  return {
    title: data.title,
    items: items.map(slideAdapter),
  };
};
